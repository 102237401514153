<template>
  <div class="overview-sum">
    <h4 class="text-center">
      {{ label }}
    </h4>
    <h5 v-if="sublabel" class="text-center">
      {{ sublabel }}
    </h5>
    <h3 v-if="emphasizedText" class="mb-5 text-center">
      {{ emphasizedText }}
    </h3>
    <ul
      class="range-list"
      v-if="normalizedList.length"
    >
      <li
        v-for="(item,i) in normalizedList"
        :key="i">
        <div class="range-line">
          <span class="key mr-1">
            {{ item.label }}
          </span>
          <span class="value">
            {{ isShowInPercent
              ? `${item.ratio}%`
              : $numberManagement.contractInteger(item.value) }}
          </span>
          <span class="bar-holder">
            <span
              class="bar-fill"
              :style="`width: ${item.ratio}%;`"
            />
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'SimpleList',
  props: {
    label: {
      type: String,
      default: '',
    },
    sublabel: {
      type: String,
      default: '',
    },
    emphasizedText: {
      type: [String, Number],
      default: '',
    },
    mainList: {
      type: [Array, Object],
    },
    isShowInPercent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {
  },

  computed: {
    normalizedList() {
      return this.normalizedDataArray(this.mainList);
    },
  },

};
</script>

<style scoped>
.overview-sum {
  padding-right: 30px;
}

.overview-sum h4 {
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.overview-sum ol,
.overview-sum ul
{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.overview-sum li {
  padding: 0;
}

/*range-list*/
.range-list {
  padding-top: 8px;
}

.range-list .v-expansion-panel-header {
  width: 100% !important;
}

.range-sub-line .range-list {
  padding-top: 0;
}

.range-line {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  padding: 0;
}

.range-sub-line {
  padding: 1em;
  background: #f0f0f0;
}

.range-list .key {
  flex: 1 1 auto;
}

.range-list.with-dd .key {
  cursor: pointer;
  text-decoration: 2px underline dotted;
}

.range-list.with-dd .range-sub-line .key {
  cursor: default;
  text-decoration: none;
}

.range-list .value {
  padding-right: 0.5em;
}

.range-list .bar-holder {
  display: block;
  height: 1.2em;
  width: 100px;
}

.range-list .bar-fill {
  display: block;
  height: 100%;
  background: #0fb0e1;
  width: 0;
}
</style>
